<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-media class="d-flex flex-column" no-body>
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="
                profile_image && profile_image !== null
                  ? profile_image
                  : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
              "
              height="80"
            />
          </b-link>
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <b-button
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="handleClickProfileImage"
          >
            Upload
            <div style="display: none">
              <b-form-file
                v-model="profile_image"
                id="fileUpload"
                accept="image/*"
                @input="handleFileChange($event, 'Customer', 'profile_image')"
              />
            </div>
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="removeprofileImage"
          >
            Reset
          </b-button>
        </b-media-body>
      </b-media>
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Lead Source</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Source"
              >
                <v-select
                  v-model="selectedLeadSource"
                  label="name"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leadSourceOption"
                  @input="leadSource"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            v-if="selectedLeadSource && selectedLeadSource.name == 'Campaign'"
          >
            <b-form-group>
              <label>Select Campaign</label>
              <v-select
                v-model="campaign"
                label="name"
                placeholder="None"
                :options="$store.state.master.campaigns"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="display ? '' : 'display:none'">
            <b-form-group>
              <label>Sub Source</label>
              <v-select
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="selectedSubSource"
                :label="setLabel"
                :options="subSourceOption"
              >
                <template #option="{ fullname, profile_image, username, color }">
                  <b-avatar
                    :src="getprofileImage(profile_image)"
                    v-if="
                      (selectedLeadSource &&
                        selectedLeadSource.name == 'Channel Partner') ||
                      selectedLeadSource.name == 'Employee' ||
                      selectedLeadSource.name == 'Franchise'
                    "
                  />
                  <span :class="'font-weight-bolder' + ' text-' + color">
                    {{ fullname }}
                  </span>

                  <span
                    :class="' text-' + color"
                    v-if="
                      (selectedLeadSource &&
                        selectedLeadSource.name == 'Channel Partner') ||
                      selectedLeadSource.name == 'Employee' ||
                      selectedLeadSource.name == 'Franchise'
                    "
                    >({{ username }})</span
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Title</label>
              <v-select
                placeholder="None"
                v-model="title"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Shri.', 'Smt.', 'Ms.', 'Mrs.', 'Mr.']"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Client's Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Surname</label>
              <b-form-input v-model="surname" placeholder="Enter Name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Father/Husband Name</label>
              <b-form-input v-model="fathername" placeholder="Enter Name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Occupation</label>
              <b-form-input v-model="occupation" placeholder="Enter Occupation" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Birth Date</label>
              <datepicker
                v-model="birthdate"
                placeholder="Select Date"
                inputClass="form-control bg-transparent"
                format="dd/MM/yyyy"
                style="background-color: transparent !important"
                :disabled-dates="{
                  from: new Date(),
                }"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Lead Date</label>
              <datepicker
                v-model="leadDate"
                placeholder="Select Date"
                inputClass="form-control bg-transparent"
                format="dd/MM/yyyy"
                style="background-color: transparent !important"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group>
              <label>Next FollowUP Date</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Date"
              >
                <b-form-datepicker
                  v-model="nextfollowupdate"
                  menu-class="w-100"
                  calendar-width="100%"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group>
              <label>Contact Person</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Contact Person"
              >
                <b-form-input
                  v-model="contactPerson"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Contact Person"

                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Mobile">
                <b-form-input
                  v-model="mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <b-form-input v-model="email" placeholder="Enter Email" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Gender">
                <v-select
                  v-model="gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['Male', 'Female']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Marital Status</label>
              <v-select
                v-model="maritalstatus"
                placeholder="None"
                :options="['Married', 'Unmaried']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="maritalstatus == 'Married' ? '' : 'display:none'">
            <b-form-group>
              <label>Anniversary Date</label>

              <datepicker
                v-model="anniversarydate"
                placeholder="Select Date"
                inputClass="form-control bg-transparent"
                format="dd/MM/yyyy"
                style="background-color: transparent !important"
                :disabled-dates="{
                  from: new Date(),
                }"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee</label>
              <b-form-input v-model="nomineename" placeholder="Enter Nominee" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Relationship</label>
              <b-form-input
                v-model="nomineerelation"
                placeholder="Enter Nominee Relationship"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Phone</label>
              <b-form-input
                v-model="nomineephone"
                maxlength="10"
                placeholder="Enter Nominee Phone"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee DOB</label>

              <datepicker
                v-model="nomineedob"
                placeholder="Select Date"
                inputClass="form-control bg-transparent"
                format="dd/MM/yyyy"
                style="background-color: transparent !important"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Current Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address Line 1"
              >
                <b-form-input
                  v-model="curaddressline1"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address Line 2"
              >
                <b-form-input
                  v-model="curaddressline2"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|digits:6"
                name="Pin Code"
              >
                <b-form-input
                  v-model="curpincode"
                  maxlength="6"
                  @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Pin Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select
                  v-model="curcity"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="City"
                  :options="curcityOption"
                  @input="clickArea($event)"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select
                  v-model="curarea"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="PostOfficeName"
                  :options="curareaOption"
                  placeholder="None"
                  @input="handleCurPincode()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Taluka</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Taluka">
                <b-form-input
                  v-model="curtaluka"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Taluka"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select
                  v-model="curstate"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="stateOptions"
                  placeholder="None"
                  label="State"
                  @input="handleCurCity()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Permanent Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
            <b-form-checkbox @input="handlePermenent" class="ml-2" inline>
              Same As Current Address
            </b-form-checkbox>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <b-form-input
                v-model="peraddressline1"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 1"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <b-form-input
                v-model="peraddressline2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <validation-provider #default="{ errors }" rules="digits:6" name="pin Code">
                <b-form-input
                  v-model="perpincode"
                  maxlength="6"
                  @input="clickPerPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Pin Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <v-select
                v-model="percity"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="percityOption"
                @input="clickPerArea($event)"
                label="City"
                placeholder="None"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <v-select
                v-model="perarea"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perareaOption"
                placeholder="None"
                @input="handlePincode()"
                label="PostOfficeName"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input
                v-model="pertaluka"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <v-select
                v-model="perstate"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="stateOptions"
                placeholder="None"
                label="State"
                @input="handleCity()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input
                v-model="panno"
                maxlength="10"
                @input="validatePanClick($event)"
                placeholder="Enter PAN No"
              />
              <small v-if="pannoValidate == false" class="text-danger"
                >Enter Valid PAN No</small
              >
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan Card</label>
              <b-form-file
                v-model="pancard"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'customer', 'pancard')"
              />
            </b-form-group>
            <attachment :data="pancard" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Aadhar No"
              >
                <b-form-input
                  v-model="aadharcardno"
                  maxlength="14"
                  @input="validateAadharClick($event)"
                  placeholder="Enter Aadhar No"
                />
                <small v-if="aadharcardnoValidate == false" class="text-danger"
                  >Enter Valid Aadhar No</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Front Side</label>
              <b-form-file
                v-model="aadharfrontside"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'customer', 'aadharfrontside')"
              />
            </b-form-group>
            <attachment :data="aadharfrontside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Back Side</label>
              <b-form-file
                v-model="aadharbackside"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'customer', 'aadharbackside')"
              />
            </b-form-group>
            <attachment :data="aadharbackside" />
          </b-col>

          <!-- <b-col md="4">
            <b-form-group>
              <label>VoterId No</label>
              <b-form-input
                v-model="voterIdNo"
                @input="validateVoterClick($event)"
                placeholder="Enter Voter No"
                />
              </b-form-group>
            </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Voter Id </label>
              <b-form-file
                v-model="voterIdPhoto"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'customer', 'voterIdPhoto')"
              />
            </b-form-group>
            <attachment :data="voterIdPhoto" />
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Area</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Area">
                <b-form-input v-model="area" :state="errors.length > 0 ? false : null" placeholder="Enter Area"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="City">
                <b-form-input v-model="city" :state="errors.length > 0 ? false : null" placeholder="Enter City"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Pin Code" rules="digits:6">
                <b-form-input v-model="pincode" maxlength="6" :state="errors.length > 0 ? false : null" placeholder="Enter Pin Code"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
                <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" re name="State">
                <b-form-input v-model="state" :state="errors.length > 0 ? false : null" placeholder="Enter State"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Remark</label>
              <b-form-textarea v-model="remark" placeholder="Enter Remark" />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="fromSales !== true && role != 'franchise' ? '' : 'display:none'"
          >
            <b-form-group>
              <label>Select Type</label>
              <v-select
                v-model="selectedType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="assigntoTypeOptions"
                placeholder="None"
                @input="handleAssign()"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="fromSales !== true && selectedType !== 'Self' ? '' : 'display:none'"
          >
            <b-form-group>
              <label>Assign to</label>
              <v-select
                v-model="selectedAssignTo"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="fullname"
                :options="assignToOption"
                placeholder="None"
              >
                <template #option="{ name, profile_image, username, color, surname }">
                  <b-avatar
                    :src="getprofileImage(profile_image)"
                    v-if="
                      selectedType == 'Channel Partner' ||
                      selectedType == 'Employee' ||
                      selectedType == 'Franchise'
                    "
                  />
                  <span :class="'font-weight-bolder ' + 'text-' + color">
                    {{ name }} {{ surname }}
                  </span>
                  <span
                    :class="'text-' + color"
                    v-if="
                      selectedType == 'Channel Partner' ||
                      selectedType == 'Employee' ||
                      selectedType == 'Franchise'
                    "
                    >({{ username }})</span
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="submitDisable"
            >
              Submit
            </b-button>
            <b-button variant="primary" v-if="fromSales !== true" @click="onClickBack"
              >Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BFormFile,
  BMediaBody,
  BLink,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../components/Attechment.vue";

export default {
  components: {
    BAvatar,
    BFormCheckbox,
    BFormFile,
    Attachment,
    flatPickr,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: ["fromSales", "selectedUser", "closePopUp", "getCustomer"],
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      campaign: "",
      selectedLeadSource: "",
      selectedSubSource: "",
      leadSourceOption: [],
      subSourceOption: [],
      leadValue: "",
      name: "",
      fathername: "",
      surname: "",
      test: "",
      leadDate: null,
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      mobile: "",
      email: "",
      curaddressline1: "",
      curaddressline2: "",
      curarea: "",
      curtaluka: "",
      curstate: "",
      curcity: "",
      curpincode: "",
      peraddressline1: "",
      peraddressline2: "",
      perarea: "",
      pertaluka: "",
      perstate: "",
      percity: "",
      perpincode: "",
      curcityOption: [],
      curareaOption: [],
      percityOption: [],
      perareaOption: [],
      remark: "",
      selectedAssignTo: "",
      stateOptions: [],
      assignToOption: [],
      fromState: "",
      ifEdit: false,
      selectedType: "",
      role: "",
      userId: "",
      fromCurState: "",
      fromState: "",

      birthdate: null,
      profile_image: "",
      aadharfrontside: "",
      aadharbackside: "",
      pancard: "",
      voterIdPhoto: "",
      aadharcardno: "",
      panno: "",
      occupation: "",
      voterIdNo: "",

      pannoValidate: true,
      aadharcardnoValidate: true,
      submitDisable: false,
      test: "",
      testpan: "",
      testaadhar: "",
      gender: "",
      maritalstatus: "",
      anniversarydate: null,
      nomineename: "",
      nomineerelation: "",
      nomineephone: "",
      nomineedob: moment(new Date()).format("DD/MM/yyyy"),
      title: "",

      allCp: "",
      cp: "",
      allFr: "",
      fr: "",
      userData: "",
      display: true,
      cpleadsource: "",
      empleadsource: "",
      selfleadsource: "",
      type: "",
      assigntoTypeOptions: ["Channel Partner", "Franchise", "Employee", "Self"],
    };
  },
  computed: {
    setLabel() {
      if (
        this.selectedLeadSource &&
        (this.selectedLeadSource.name == "Channel Partner" ||
          this.selectedLeadSource.name == "Franchise" ||
          this.selectedLeadSource.name == "Employee")
      ) {
        return "fullname";
      } else {
        return "name";
      }
    },
  },
  watch: {
    "$store.state.app.all_users": {
      handler: "leadSource",
      immediate: true,
    },
  },
  mounted() {
    const rights = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin" && !this.fromSales && !this.selectedUser) {
      rights.map((item) => {
        if (item.modulename == "Customer") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/customer");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/customer");
          }
        }
      });
      this.assigntoTypeOptions.splice(1, 1);
    }

    this.userData = this.$store.state.app.user_data;
    this.role = this.userData.role;
    this.userId = this.userData.id;
    this.type = this.userData.type;

    if (this.fromSales == true) {
      this.selectedAssignTo = this.selectedUser;
    }
    if (this.role == "franchise") {
      this.selectedType = "Franchise";
      this.selectedAssignTo = this.userData;
      this.assignToOption = [this.userData];
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    if (this.fromSales == true) {
      this.ifEdit = false;
    }
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getAllFr() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (
            this.selectedLeadSource &&
            this.selectedLeadSource.name == "Franchise" &&
            this.role == "admin"
          ) {
            this.subSourceOption = response.data.data;
          }
          if (this.selectedType == "Franchise" && this.role == "admin") {
            this.assignToOption = response.data.data;
          }
        })
        .catch((error) => console.log(error, "error"));
    },

    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async handleSubsource(id) {
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        this.selectedSubSource = response.data.data[0];
      });
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateVoterClick(e) {
      this.voterIdNo = this.voterIdNo.toUpperCase();
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.aadharcardno.length == 4
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      this.aadharcardno.length == 9
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      if (regex.test(this.aadharcardno) == true) {
        this.aadharcardnoValidate = true;
      } else {
        this.aadharcardnoValidate = false;
      }
    },
    async handleFileChange(e, type, name) {
      this.submitDisable = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          const vue = this;
          this.submitDisable = false;

          vue[name] = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => {
          this.submitDisable = false;
        });
    },
    removeprofileImage() {
      this.profileImage = null;
    },
    handleClickProfileImage() {
      document.getElementById("fileUpload").click();
    },
    handlePincode() {
      if (this.fromState == true) {
        this.perpincode = this.perarea.Pincode;
      }
    },
    handleCurPincode() {
      if (this.fromCurState == true) {
        this.curpincode = this.curarea.Pincode;
      }
    },
    async handleCurCity() {
      this.curareaOption = [];
      this.curcityOption = [];
      this.fromCurState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.curstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curcityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.perareaOption = [];
      this.percityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.perstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.percityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.percity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.perareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickPerArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      // this.perareaOption = []
      // this.perStateData.map((item) => {
      //   if (item.City == e) {
      //     this.perareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPerPincode() {
      this.percity = "";
      this.perarea = "";
      this.perstate = "";
      this.perareaOption = [];
      this.percityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.perpincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.perpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.percityOption = [];
            this.perareaOption = response.data.data;
            this.perStateData = response.data.data;
            this.perstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.percityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleCurArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.curcity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromCurState == true) {
        this.handleCurArea();
      }
      // this.curareaOption = []
      // this.curStateData.map((item) => {
      //   if (item.City == e) {
      //     this.curareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPincode() {
      this.curcity = "";
      this.curarea = "";
      this.curstate = "";
      this.curareaOption = [];
      this.curcityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.curpincode)) {
        this.fromCurState = false;

        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.curpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.curcityOption = [];
            this.curStateData = response.data.data;
            this.curareaOption = response.data.data;
            this.curstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.curcityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    handlePermenent(e) {
      if (e == true) {
        this.perarea = this.curarea;
        this.perstate = this.curstate;
        this.percity = this.curcity;
        this.pertaluka = this.curtaluka;
        this.perpincode = this.curpincode;
        this.peraddressline1 = this.curaddressline1;
        this.peraddressline2 = this.curaddressline2;
      } else {
        this.perarea = "";
        this.perstate = "";
        this.percity = "";
        this.pertaluka = "";
        this.perpincode = "";
        this.peraddressline1 = "";
        this.peraddressline2 = "";
      }
    },

    async handleAssign() {
      this.assignToOption = [];
      if (this.selectedType == "Channel Partner") {
        if (this.userData.role != "employee") {
          this.getCpDownline();
        } else {
          this.assignToOption = this.$store.state.app.all_users.filter(
            (item) => item.assignto == this.userData.id && item.role == "channelpartner"
          );
        }
      } else if (this.selectedType == "Franchise") {
        this.getAllFr();
      } else if (this.selectedType == "Self") {
        this.selectedAssignTo = this.userData;
      } else {
        if (this.userData.role != "channelpartner") {
          this.getEmployee();
        } else {
          this.assignToOption = this.userData.assignto;
        }
      }

      if (!this.selectedType) {
        this.assignToOption = [];
      }
    },
    async self() {
      this.assignToOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/cpparentchild`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.selectedAssignTo = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelPartner(id) {
      this.assignToOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/cpparentchild`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (this.role !== "admin") {
            this.assignToOption.push(response.data.data);
          }
          response.data.data.children.map((item) => {
            if (item.role == "channelpartner") {
              this.assignToOption.push(item);
            }
          });
          if (this.role !== "admin" && response.data.data.parent) {
            this.assignToOption.push(response.data.data.parent);
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getTeam(id) {
      this.assignToOption = [];

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getteam`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },

    onClickBack() {
      this.$router.push("/crm/customer");
      this.handleCloseModel();
    },
    async leadSource() {
      this.campaign = "";

      this.leadValue = this.selectedLeadSource ? this.selectedLeadSource.name : "";
      if (this.selectedLeadSource) {
        this.leadValue = this.selectedLeadSource ? this.selectedLeadSource.name : "";
        if (this.leadValue == "Self") {
          this.display = false;
        } else {
          this.display = true;
        }
        this.subSourceOption = [];
        if (this.leadValue == "Channel Partner") {
          if (this.userData.role == "channelpartner") {
            this.getCpDownline();
          } else {
            this.subSourceOption = this.$store.state.app.all_users.filter(
              (item) => item.assignto == this.userData.id
            );
          }
        } else if (this.leadValue == "Franchise") {
          this.getAllFr();
        } else if (this.leadValue == "Campaign") {
          this.$store.dispatch("master/getCampaigns");
        } else if (this.leadValue == "Employee") {
          if (this.userData.role != "channelpartner") {
            this.getEmployee();
          } else {
            this.subSourceOption = this.userData.assignto;
          }
        } else if (this.leadValue == "Self") {
          this.selectedSubSource = this.userData;
        } else {
          this.subSourceOption = [];
          this.leadSourceOption.map((item) => {
            if (item.parent == this.selectedLeadSource.id) {
              this.subSourceOption.push(item);
            }
          });
        }
      }
    },
    handleCP(id) {
      this.allCp.map((item) => {
        if (item.parent == id) {
          this.cp.push(item);
          this.handleCP(item.id);
        }
      });
    },
    async getCpDownline(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getChannelpartner`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.cp = [];
          this.allCp = json.data.data;
          if (this.role == "admin" || this.userData.type == "leadmanager") {
            if (
              this.selectedLeadSource &&
              this.selectedLeadSource.name == "Channel Partner"
            ) {
              this.subSourceOption = this.allCp;
            }
            if (this.selectedType == "Channel Partner") {
              this.assignToOption = this.allCp;
            }
          } else {
            this.allCp.map((item) => {
              if (item.id == this.userId) {
                item.color = "success";
                this.cp.push(item);
              }
            });
            this.handleCP(this.userId);
            if (this.selectedLeadSource.name == "Channel Partner") {
              this.subSourceOption = this.cp;
            }
            if (
              this.selectedType.name == "Channel Partner" ||
              this.userData.role == "channelpartner"
            ) {
              this.assignToOption = this.cp;
            }
          }
          if (id !== undefined) {
            this.subSourceOption.map((item) => {
              if (item.id == id) {
                this.subsource = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    handleFr(id) {
      this.allFr.map((item) => {
        if (item.parent == id) {
          this.fr.push(item);
          this.handleFr(item.id);
        }
      });
    },
    async getEmployee() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getEmployee`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          if (this.selectedLeadSource.name == "Employee") {
            this.subSourceOption = json.data.data;
          }
          if (this.selectedType == "Employee") {
            this.assignToOption = json.data.data;
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      (this.selectedLeadSource =
        item.leadsource && item.leadsource.length > 0 ? item.leadsource[0] : ""),
        this.leadSource(this.selectedLeadSource);

      (this.selectedSubSource =
        item.subsource && item.subsource.length > 0 ? item.subsource[0] : ""),
        (this.name = item.name),
        (this.name = item.name);
      this.fathername = item.fathername;
      this.surname = item.surname;
      this.leadDate = moment(new Date(item.leaddate)).format("YYYY-MM-DD");
      this.mobile = item.mobile;
      this.email = item.email;
      this.campaign = item.campaign;
      this.test = item.email;
      this.curaddressline1 = item.curaddressline1;
      this.curaddressline2 = item.curaddressline2;
      this.curarea = item.curarea;
      this.curtaluka = item.curtaluka;
      this.curcity = item.curcity;
      this.curpincode = item.curpincode;
      this.curstate = item.curstate;
      this.peraddressline1 = item.peraddressline1;
      this.peraddressline2 = item.peraddressline2;
      this.perarea = item.perarea;
      this.pertaluka = item.pertaluka;
      this.percity = item.percity;
      this.perpincode = item.perpincode;
      this.perstate = item.perstate;
      this.remark = item.remarks;
      this.birthdate = moment(item.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.aadharcardno = item.aadharcardno;
      this.panno = item.panno;
      this.voterIdNo = item.voterIdNo;
      this.aadharfrontside = item.aadharfrontside;
      this.aadharbackside = item.aadharbackside;
      this.pancard = item.pancard;
      this.voterIdPhoto = item.voterIdPhoto;
      this.profile_image = item.profile_image;
      this.gender = item.gender;
      this.maritalstatus = item.maritalstatus;
      this.anniversarydate = item.anniversarydate;
      this.occupation = item.occupation;
      this.nomineename = item.nomineename;
      this.nomineerelation = item.nomineerelation;
      this.nomineephone = item.nomineephone;
      this.nomineedob = item.nomineedob;
      this.title = item.title;
      this.selectedAssignTo = item.assignto.length > 0 ? item.assignto[0] : "";
      this.getCustomerUser(item.assignto[0]);
      this.location();

      this.handleAssign();
      // this.clickPincode()
    },
    location() {
      this.handleCity();
      this.handleCurCity();
      this.handleCurArea();
      this.handleArea();
    },
    async getCustomerUser(assignto) {
      if (assignto.role == "channelpartner") {
        this.selectedType = "Channel Partner";
        if (this.userData.role == "channelpartner") {
          this.getCpDownline();
        } else {
          this.assignToOption = this.$store.state.app.all_users.filter(
            (item) => item.assignto == this.userData.id && item.role == "channelpartner"
          );
        }
      } else if (assignto.role == "franchise") {
        this.selectedType = "Franchise";
        this.getFrDownline();
      } else if (assignto.role == "employee") {
        this.selectedType = "Employee";
        if (this.userData.role != "channelpartner") {
          this.getEmployee();
        } else {
          this.assignToOption = this.userData.assignto;
        }
      } else {
        this.selectedType = "Self";
      }
    },

    reset() {},
    submitForm(e) {
      const data = {
        leaddate: moment(this.leadDate).format("DD/MM/yyyy"),
        branch: 1,
        company: 1,
        active: 1,
        leadsource: this.selectedLeadSource.id,
        subsource: this.selectedSubSource ? this.selectedSubSource.id : "",
        name: this.name,
        fathername: this.fathername,
        surname: this.surname,
        // nextfollowupdate: moment(new Date(this.nextfollowupdate)).format(
        // "DD/MM/yyyy"
        // ),
        mobile: this.mobile,
        email: this.email,
        curaddressline1: this.curaddressline1,
        curaddressline2: this.curaddressline2,
        curarea: this.curarea.PostOfficeName,
        curtaluka: this.curtaluka,
        campaign: this.campaign?.id,
        curcity: this.curcity.City,
        curpincode: this.curpincode,
        curstate: this.curstate.State,
        peraddressline1: this.peraddressline1,
        peraddressline2: this.peraddressline2,
        perarea: this.perarea ? this.perarea.PostOfficeName : "",
        pertaluka: this.pertaluka,
        percity: this.percity ? this.percity.City : "",
        perpincode: this.perpincode,
        perstate: this.perstate ? this.perstate.State : "",
        remarks: this.remark,
        assignto: this.selectedAssignTo.id,
        birthdate: moment(this.birthdate).format("DD/MM/yyyy"),
        aadharfrontside: this.aadharfrontside,
        aadharbackside: this.aadharbackside,
        pancard: this.pancard,
        voterIdPhoto: this.voterIdPhoto,
        profile_image: this.profile_image,
        aadharcardno: this.aadharcardno,
        gender: this.gender,
        maritalstatus: this.maritalstatus,
        anniversarydate: moment(this.anniversarydate).format("DD/MM/yyyy"),
        occupation: this.occupation,
        panno: this.panno,
        voterIdNo: this.voterIdNo,
        nomineename: this.nomineename,
        nomineerelation: this.nomineerelation,
        nomineephone: this.nomineephone,
        nomineedob: moment(this.nomineedob).format("DD/MM/yyyy"),
        title: this.title,
      };
      if (this.ifEdit == true) {
        delete data.password;
      }
      if (this.email == this.test) {
        delete data.email;
      }
      if (this.panno == this.testpan) {
        delete data.panno;
      }
      if (this.aadharcardno == this.testaadhar) {
        delete data.aadharcardno;
      }
      if (this.voterIdNo == this.testvoterIdNo) {
        delete data.voterIdNo;
      }

      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/customer/${this.$route.params.id}`
              : `${baseApi}/customer`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              if (this.fromSales == true) {
                this.getCustomer(this.selectedUser.id);
                this.closePopUp();
              } else {
                this.$router.push("/crm/customer");
              }
            })
            .catch((error) => {
              this.submitDisable = false;

              let email, name, message;
              const code = error.toString().includes("409");
              if (code) {
                email = error.response.data.message.original.email
                  ? error.response.data.message.original.email[0]
                  : "";
                name = error.response.data.message.original.name
                  ? error.response.data.message.original.name[0]
                  : "";
                message = email + "  " + name;
              }
              this.$swal({
                title: "Error!",
                text: code ? `${message}` : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async init() {
      this.getLeadSource();
      // this.getAssignSource();
      this.getState();
    },
    async getLeadSource(id, subid) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leadsourcedata`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.leadSourceOption = response.data.data.filter((item) => {
            if (
              item.name == "Franchise" &&
              this.userData.role != "admin" &&
              this.userData.role != "frachise"
            ) {
              return false;
            } else if (
              (item.name == "Channel Partner" || item.name == "Employee") &&
              this.userData.role == "frachise"
            ) {
              return false;
            } else if (
              (this.userData.role == "cannelpartner" ||
                this.userData.role == "employee") &&
              item.name == "Frachise"
            ) {
              return false;
            } else {
              return true;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getSubSource(id) {
      this.leadSourceOption.map((item) => {
        if (item.id == id) {
          this.selectedSubSource = item;
        }
      });
    },
    async getAssignSource(id) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${userData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToOptions = [];
          this.assignToOptions = response.data.data;
          this.assignToOptions.map((item) => {
            this.assignToOption.push(item);
            if (item.children && JSON.stringify(item.children) !== "[]") {
              this.assigntest(item.children);
            }
            if (id != undefined && id == item.id) {
              this.selectedAssignTo = item;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    assigntest(data) {
      data.map((item) => {
        this.assignToOption.push(item);
        if (item.children && JSON.stringify(item.children) !== "[]") {
          this.assigntest1(item.children);
        }
      });
    },
    assigntest1(data) {
      data.map((item) => {
        this.assignToOption.push(item);
        if (item.children && JSON.stringify(item.children) !== "[]") {
          this.assigntest(item.children);
        }
      });
    },
  },
};
</script>
